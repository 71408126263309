import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';

const GroupFilter = ({ getDatabyGroup }) => {
    const [groupBy, setGroupBy] = useState('date');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleGroupBy = (value) => {
        setGroupBy(value);
    };

    const handleDropdownToggle = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    useEffect(() => {
        getDatabyGroup(groupBy);
    }, [groupBy, getDatabyGroup]);

    return (
        <Dropdown style={{ width: '50px' }} onToggle={handleDropdownToggle}>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
                Group by: <span className='group-by-text'>{groupBy}</span> {isDropdownOpen ? <BsChevronUp className='mx-2' /> : <BsChevronDown className='mx-2' />}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item
                    onClick={() => handleGroupBy('date')}
                    className={groupBy === 'date' ? 'active' : ''}
                >
                    Date
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => handleGroupBy('project')}
                    className={groupBy === 'project' ? 'active' : ''}
                >
                    Project
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => handleGroupBy('task')}
                    className={groupBy === 'task' ? 'active' : ''}
                >
                    Task
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => handleGroupBy('user')}
                    className={groupBy === 'user' ? 'active' : ''}
                >
                    Member
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default GroupFilter;
