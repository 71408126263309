import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import RoutesList from '../constants/RoutesList';
import MainScreen from '../dashboard/MainScreen';
import logo from '../assets/images/Logo.svg';
import TimeActivitys from '../dashboard/reports/TimeActivitys';
import MainContentHeader from '../components/MainContentHeader';

const sidebarItems = [
  { path: RoutesList.TIMESHEETS, label: 'Timesheets' },
  { path: RoutesList.REPORTS, label: 'Reports' },
];

const Layout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically navigate to TIMESHEETS route when Layout component is rendered
    if (pathname === '/') {
      navigate(RoutesList.TIMESHEETS);
    }
  }, [pathname, navigate]);

  const isActive = (path) => pathname === path ? 'active' : '';

  const renderSidebarLinks = () => {
    return (
      sidebarItems.map((item, index) => (
        <li key={index}>
          <Link to={item.path} className={`nav-link ${isActive(item.path)} text-decoration-none`}>
            {item.label}
          </Link>
        </li>
      ))
    );
  };

  return (
    <Container fluid>
      <Row className='layout_container'>
        {/* Sidebar */}
        <Col md={2} className='side_dashboard'>
          <div className="sidebar-layout">
            <img src={logo} alt="Logo" className="sidebar-logo" />
            <ul className='m-2 list-unstyled'>
              {renderSidebarLinks()}
            </ul>
          </div>
        </Col>

        {/* Main Content */}
        <Col md={10}>
          <MainContentHeader pathname={pathname} />
          <Routes>
            <Route path={RoutesList.TIMESHEETS} element={<MainScreen />} />
            <Route path={RoutesList.REPORTS} element={<TimeActivitys />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
