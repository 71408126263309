// src/helper/LoaderHelper.js
import React from 'react';

const LoaderHelper = () => {
    return (
        <div className="loader"></div>
    );
};

export default LoaderHelper;
