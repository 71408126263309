import React, { useEffect, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { ALL, CLEAR, FILTERS, PROJECTS, SAVE, SERVER_ERROR, TASK, USERS } from '../constants/Constant';
import ApiRouteList from '../constants/ApiRouteList';
import { toast } from 'react-toastify';
import instance from '../services/api.service';
import { useLogoutAndNavigate } from '../services/user.service';

const ProjectFilterDrawer = ({ isShowDrawer, handleCloseDrawer, handleSaveFilters, fetchData }) => {
    const [isClient, setIsClient] = useState([]);
    const [isProject, setIsProject] = useState([]);
    const [isTask, setIsTask] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [projectDetails, setProjectDetails] = useState([]);
    const [taskDetails, setTaskDetails] = useState([]);
    const [projectMember, setProjectMember] = useState([]);

    const animatedComponents = makeAnimated();
    const logOut = useLogoutAndNavigate();

    useEffect(() => {
        if (isShowDrawer) {
            getAllUsersDetails();
            getAllProjectsDetails();
            getAllTasksDetails();
        }
        // eslint-disable-next-line
    }, [projectMember, isShowDrawer]);

    useEffect(() => {
        ProjectTaskMember();
        // eslint-disable-next-line
    }, [isClient, isProject, isTask]);

    const getAllUsersDetails = async () => {
        try {
            const { data } = await instance.get(ApiRouteList.USER);
            if (data?.users?.length) {
                if (projectMember?.data && (isProject?.length || isTask?.length)) {
                    const filteredUserIds = projectMember.data.map(member => member.userId);
                    const users = data.users
                        .filter(user => filteredUserIds.includes(user.id))
                        .map(user => ({
                            value: user.id,
                            label: `${user.firstName} ${user.lastName}`,
                        }));
                    setUserDetails(users);
                } else {
                    const users = data.users.map(user => ({
                        value: user.id,
                        label: `${user?.firstName} ${user?.lastName}`,
                    }));
                    setUserDetails(users);
                }
            }
        } catch (error) {
            toast.error(error.message || SERVER_ERROR);
        }
    };

    const getAllProjectsDetails = async () => {
        try {
            const { data } = await instance.get(ApiRouteList.FILTER_PROJECT);
            if (data?.projects?.length) {
                if (projectMember?.data && (isClient?.length || isTask?.length)) {
                    const filteredUserIds = projectMember.data.map(member => member?.projectId);
                    const projects = data.projects
                        .filter(pro => filteredUserIds.includes(pro.id)).map(user => ({
                            value: user.id,
                            label: user.projectName,
                        }));
                    setProjectDetails(projects);
                } else {
                    const projects = data?.projects.map(project => ({
                        value: project.id,
                        label: project.projectName,
                    }));
                    setProjectDetails(projects);
                }
            }
        } catch (error) {
            toast.error(error.message || SERVER_ERROR);
        }
    };

    const getAllTasksDetails = async () => {
        try {
            const { data } = await instance.get(ApiRouteList.FILTER_TASK);
            if (data?.tasks) {
                if (projectMember?.data && (isClient?.length || isProject?.length)) {
                    const filteredUserIds = projectMember.data.map(member => member?.taskId);
                    const tasks = data.tasks
                        .filter(task => filteredUserIds.includes(task.id)).map(task => ({
                            value: task.id,
                            label: task.taskName,
                        }));
                    setTaskDetails(tasks);
                } else {
                    const tasks = data?.tasks.map(task => ({
                        value: task.id,
                        label: task.taskName,
                    }));
                    setTaskDetails(tasks);
                }
            }
        } catch (error) {
            toast.error(error.message || SERVER_ERROR);
        }
    };

    const ProjectTaskMember = async () => {
        try {
            if (isClient?.length || isProject?.length || isTask?.length) {
                const { data } = await instance.post(ApiRouteList.PROJECT_TASK_MEMBER, {
                    userIds: isClient,
                    projectIds: isProject,
                    taskIds: isTask
                });
                setProjectMember(data);
            }
        } catch (error) {
            if (error.response?.data) {
                logOut();
                toast.error(error.response.data.reason);
            } else {
                toast.error(error.message || SERVER_ERROR);
            }
        }
    };

    const handleChangeClient = (selectedOptions) => {
        const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setIsClient(selectedIds);
    };

    const handleChangeProject = (selectedOptions) => {
        const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setIsProject(selectedIds);
    };

    const handleChangeTask = (selectedOptions) => {
        const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setIsTask(selectedIds);
    };

    const handleClearFilters = () => {
        setIsClient([]);
        setIsProject([]);
        setIsTask([]);
        ProjectTaskMember();
        fetchData()
    };

    const handleSave = () => {
        handleSaveFilters(isClient, isProject, isTask);
    };

    return (
        <>
            <Offcanvas show={isShowDrawer} onHide={handleCloseDrawer} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='text-capitalize text-secondary'>{FILTERS}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='project-filter'>
                        <div className="filter-section">
                            <div className="filter-title text-secondary">{USERS}</div>
                            <Select
                                className="select-box"
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={userDetails}
                                onChange={handleChangeClient}
                                value={userDetails.filter(user => isClient.includes(user.value))}
                                placeholder={`${ALL} ${USERS}`}
                            />
                        </div>
                        <div className="filter-section">
                            <div className="filter-title text-secondary">{PROJECTS}</div>
                            <Select
                                className="select-box"
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={projectDetails}
                                onChange={handleChangeProject}
                                value={projectDetails.filter(project => isProject.includes(project.value))}
                                placeholder={`${ALL} ${PROJECTS}`}
                            />
                        </div>
                        <div className="filter-section">
                            <div className="filter-title text-secondary">{TASK}</div>
                            <Select
                                className="select-box"
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={taskDetails}
                                onChange={handleChangeTask}
                                value={taskDetails.filter(task => isTask.includes(task.value))}
                                placeholder={`${ALL} ${TASK}`}
                            />
                        </div>
                    </div>
                    <div className="formBtn">
                        <Button className="btn btn-primary" type="button" onClick={handleClearFilters}>{`${CLEAR} ${FILTERS}`}</Button>
                        <Button className="btn btn-primary mt-2" type="button" onClick={handleSave}>{`${SAVE} ${FILTERS}`}</Button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default ProjectFilterDrawer;
