import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userList: ''
}
const isUserList = createSlice({
    name: 'isUserList',
    initialState: initialState,
    reducers: {
        onSetUserList: (state,
            action) => {
            state.userList = action.payload.userList;
        },
        onClearUserList: (state) => {
            state.userList = ""
        },
    }

})

export const { onSetUserList, onClearUserList } = isUserList.actions
export default isUserList.reducer;