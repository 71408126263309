import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ERRORS, LOGIN, SERVER_ERROR, TIME_TRACKER } from "../constants/Constant";
import instance from "../services/api.service";
import ApiRouteList from "../constants/ApiRouteList";
import { useNavigate } from "react-router-dom";
import Storage from "../services/localStorage.service";
import RoutesList from "../constants/RoutesList";
import { toast } from 'react-toastify';
import logo from '../assets/images/Logo.svg'
import DocumentTitleHelper from "../helper/DocumentTitleHelper";
import HidePassWord from "../assets/images/Hide.svg"
import { FaRegEye } from "react-icons/fa";

const SignIn = () => {
  const navigate = useNavigate();
  DocumentTitleHelper(TIME_TRACKER)

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const logInSchema = Yup.object().shape({
    email: Yup.string().email(ERRORS.email.invalid).required(ERRORS.email.required),
    password: Yup.string().required(ERRORS.password.required),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: logInSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = { ...values }
        payload.username = payload.email;
        delete payload.email
        payload.isAdmin = true;
        const { data, status } = await instance.post(ApiRouteList.USER_LOGIN, payload);

        if (status === 200) {
          const { token, success, message } = data;
          Storage.setToken(token);
          Storage.setUserData(success);
          toast.success(message);
          navigate(`${RoutesList.TIMESHEETS}`);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message === 'Wrong crediantials' ? 'Wrong Email or Password' : error?.response?.data?.message || SERVER_ERROR);
      } finally {
        setLoading(false);
      }
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      {/* Header */}
      <header className="header">
        <div className="container">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
      </header>

      {/* Form Container */}
      <section className="loginForm">
        <div className="container">
          <form className="mx-auto rounded-1" onSubmit={formik.handleSubmit}>
            <h5 className="heading5">Sign in to Leemo Track</h5>
            <FormGroup label="Email address" controlId="inputEmail">
              <Form.Control
                type="email"
                placeholder="Email address"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                classNameName="form-control border-0 shadow-none"
                autoFocus
              />
              {formik.touched.email && formik.errors.email && (
                <div classNameName="error" style={{ color: 'red' }}>{formik.errors.email}</div>
              )}
            </FormGroup>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className="form-control border-0 shadow-none"
                />
                <div className="input-group-append">
                  <button type="button" className="field-icon" onClick={togglePasswordVisibility}>
                    {showPassword ? <FaRegEye size={20} color="gray" /> : <img src={HidePassWord} alt="icon" />}
                  </button>
                </div>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className="error">{formik.errors.password}</div>
              )}
            </Form.Group>

            <div className="formBtn">
              <Button className="btn btn-primary" type="submit" disabled={loading}>
                {loading ? 'Log in...' : LOGIN}
              </Button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

const FormGroup = ({ label, controlId, children }) => (
  <Form.Group controlId={controlId} classNameName="form-group">
    <Form.Label classNameName="field-title">{label}</Form.Label>
    {children}
  </Form.Group>
);

export default SignIn;
