import { useNavigate } from "react-router-dom";
import RoutesList from "../constants/RoutesList";
import Storage from "./localStorage.service";
import SessionStorage from "./sessionStorage.service";

let TOKEN = null;

export const setAdminToken = () => {
  let token = Storage.getToken() || '';
  return token
  // TOKEN = CryptoJSService.decryptText(token);
}
export const getAdminToken = () => {
  return TOKEN;
}

export const useLogoutAndNavigate = () => {
  const navigate = useNavigate();

  const logOutAndNavigate = () => {
    Storage.clearAllData();
    SessionStorage.clearAllId()
    navigate(RoutesList.SIGNIN);
  };

  return logOutAndNavigate;
};