import React, { useEffect, useRef, useState, useCallback, Fragment } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { NO_SCREENSHORT_FOUND, SCREENSHOTS, SERVER_ERROR } from '../constants/Constant';
import ApiRouteList from '../constants/ApiRouteList';
import instance from '../services/api.service';
import DocumentTitleHelper from '../helper/DocumentTitleHelper';
import ImageModal from '../components/ImageModal';
import ProgressBar from '../components/ProgressBar';
import ActivityTimeWork from '../components/ActivityTimeWork';
import DatePickerCalender from '../components/DatePickerCalender';
import SpinnerLoading from '../helper/SpinnerLoading';
import moment from 'moment';
import UserSelecter from '../components/UserSelecter';
import { GiSandsOfTime } from "react-icons/gi";
import { useLogoutAndNavigate } from '../services/user.service';
import LoaderHelper from '../helper/LoaderHelper';
import SessionStorage from '../services/sessionStorage.service';

const MainScreen = () => {
    const [isImages, setIsImages] = useState([]);
    const [isOpanImage, setIsOpenImage] = useState(false);
    const [imageId, setImageId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isDate, setIsDate] = useState('');
    const [isUserId, setIsUserId] = useState(SessionStorage.getUserId() || ''); // Get the user ID from session storage on mount
    const [isActivity, setIsActivity] = useState('');
    const mailMounted = useRef(false);
    const [imageLoadingStates, setImageLoadingStates] = useState({});

    DocumentTitleHelper(SCREENSHOTS);
    const logOut = useLogoutAndNavigate();


    useEffect(() => {
        if (!mailMounted.current) {
            getScreenshort();
            mailMounted.current = true;
        } else if (isDate !== '') {
            getScreenshort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserId, isDate]);

    const getDateDetails = useCallback((date) => {
        const dateFormat = moment(new Date(date)).format('YYYY-MM-DD');
        setIsDate(dateFormat);
    }, []);

    const getScreenshort = useCallback(async () => {
        try {
            setIsLoading(true);
            if (isDate?.length && isUserId) {
                const { data } = await instance.get(`${ApiRouteList.TASK_RECORDS(isUserId)}?date=${isDate}`);
                if (data?.data) {
                    const sortedReport = data?.data?.sort((a, b) => {
                        const timeA = new Date('1970/01/01 ' + a.timeSlabFrom).getTime();
                        const timeB = new Date('1970/01/01 ' + b.timeSlabFrom).getTime();
                        return timeB - timeA;
                    }) || [];
                    const finalData = sortedReport.filter((item, index, self) =>
                        index === self.findIndex((t) => t.timeSlabFrom === item.timeSlabFrom && t.timeSlabTo === item.timeSlabTo)
                    );
                    setIsImages(finalData);
                    setIsActivity(data?.data);
                    setImageLoadingStates(data?.data.reduce((acc, image) => {
                        acc[image.id] = true;
                        return acc;
                    }, {}));
                }
            }
        } catch (error) {
            if (error.response?.data) {
                logOut();
                toast.error(error.response.data.reason);
            } else {
                toast.error(error.message || SERVER_ERROR);
            }
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }

        // eslint-disable-next-line
    }, [isUserId, isDate]);

    const openFullScreen = useCallback((id) => {
        setImageId(id);
        setIsOpenImage(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsOpenImage(false);
    }, []);

    const handleUserSelect = (id) => {
        setIsUserId(id)
        // Storage.setToken(token);
        SessionStorage.setUserId(id)
    }

    const handleImageLoad = useCallback((id) => {
        setImageLoadingStates((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    }, []);

    const handleImageError = useCallback((id) => {
        setImageLoadingStates((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    }, []);
    return (
        <>
            {isLoading && <SpinnerLoading />}
            {isOpanImage && <ImageModal imageInfo={isImages} onHide={closeModal} isModalShow={isOpanImage} imageId={imageId} />}
            <div className="m-4 calender-main">
                <DatePickerCalender getDateDetails={getDateDetails} />
                <UserSelecter onUserSelect={handleUserSelect} />
            </div>
            <ActivityTimeWork isActivity={isActivity} />
            <Row>
                <Col md={12} className="image-container">
                    {isImages?.map((image, index) => {
                        const lowResUrl = `${image?.screenshotThumbnailUrl}&res=low`;
                        return (
                            <Card key={index} style={{ width: '16rem', margin: '8px', border: 'none' }}>
                                <Card.Body>
                                    {image?.length < 0 ? <Fragment>
                                        <div className="no_activity">
                                            <span>No activity</span>
                                        </div>
                                    </Fragment> : image?.screenshotThumbnailUrl.length ? (
                                        <Fragment>
                                            {imageLoadingStates[image.id] && <LoaderHelper />}
                                            <img
                                                src={lowResUrl}
                                                alt={`alt ${image.id}`}
                                                className="gallery-image"
                                                onClick={() => openFullScreen(image?.id)}
                                                onLoad={() => handleImageLoad(image.id)}
                                                onError={() => handleImageError(image.id)}
                                                role='button'
                                                style={{ display: imageLoadingStates[image.id] ? 'none' : 'block' }} // Hide the image while it's loading
                                            />
                                            <ProgressBar image={image} />
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <div className="idle-image">
                                                <i className='idle_icon'><GiSandsOfTime /></i>
                                                <span className='idle_time'>Idle time</span>
                                            </div>
                                            <ProgressBar image={image} />
                                        </Fragment>
                                    )}
                                </Card.Body>

                            </Card>
                        )
                    })}
                </Col>
            </Row>
            <div className={isImages?.length > 0 ? "d-none" : " text-center m-4 shadow-none p-3 mb-5 bg-light rounded"}>
                <span>
                    {NO_SCREENSHORT_FOUND}
                </span>
            </div>
        </>
    );
};

export default MainScreen;
