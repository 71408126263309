import React from 'react';
import { Spinner } from 'react-bootstrap';

const SpinnerLoading = ({ text = 'Loading...', variant = 'primary', size = 'md' }) => {
  return (
    <div className="spinner-overlay">
      <div className="spinner-container d-flex align-items-center justify-content-center">
        <Spinner animation="border" variant={variant} size={size} role="status" className="spinner-border">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        {text && <span className="ms-2 loading-text">{text}</span>}
      </div>
    </div>
  );
};

export default SpinnerLoading;
