export const USER_STORAGE_KEY = "TOKEN";
export const ERRORS = {
    firstName: {
        required: 'FirstName is required',
    },
    lastName: {
        required: "LastName is required",
    },
    email: {
        required: 'Email is required',
        invalid: 'Invalid Email!'
    },
    user: {
        required: 'User is required',
    },
    password: {
        required: 'Password is required',
        min: 'Password must be at least 8 characters',
        max: 'Maximum Password length less than 25 characters',
        char: 'Password must contain one uppercase, one lowercase, one number and one special case character',
        match: 'Confirm password must match'
    },
}
export const NO_DATA_FOUND = "No data found"
export const NO_SCREENSHORT_FOUND = "No screenshort found"
export const SERVER_ERROR = 'Internal Server Error';
export const LEEMO_TRACK = 'Leemo Track';
export const TIME_TRACKER = 'Time Tracker';
export const USERS = 'Users';
export const DASHBOARD = 'Dashboard';
export const USER_LIST = 'User List';
export const LOGIN = 'LOG IN';
export const FORGOT_PASSWORD = 'Forgot your password? Click here';
export const DONT_HAVE_ACCOUNT = 'Don’t have an account?';
export const LOGIN_SSO = 'Log in with SSO';
export const GET_START = 'Get Started';
export const SCREENSHOTS = 'Timesheets';
export const REPORTS = 'Reports';
export const RESET_PASSWORD = 'Reset Password';
export const BACK_TO_LOGIN = 'Back To Login';
export const CLICK_HEAR = 'Click here';
export const PASSWORD = 'Password';
export const CURRENT = 'Current';
export const NEW = 'New';
export const COMFIRM = 'Confirm';
export const FILTERS = 'filters';
export const CLEAR = 'Clear';
export const SAVE = 'Save';
export const MEMBERS = 'Members';
export const PROJECTS = 'Projects';
export const ALL = 'All';
export const TASK = 'Task';

