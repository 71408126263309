import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { HiArrowLongLeft, HiArrowLongRight } from 'react-icons/hi2';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';
import DateRangePickerComponent from './DateRangePickerComponent';
import { FaRegCalendarAlt } from 'react-icons/fa';

const DatePickerCalender = ({ getDateDetails }) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const currentDate = new Date();
    const datePickerRef = useRef(null);
    const { pathname } = useLocation();

    useEffect(() => {
        getDateDetails(pathname === '/reports' ? { startDate, endDate } : startDate);
        // eslint-disable-next-line
    }, [startDate, endDate, pathname]);

    const handleForwardClick = () => {
        if (pathname === '/reports') {
            const newEndDate = endDate ? new Date(endDate.getTime() + 7 * 24 * 60 * 60 * 1000) : new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
            setEndDate(newEndDate);
        } else {
            const newDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000)
            setStartDate(newDate);
        }
    };

    const handleBackwardClick = () => {
        if (pathname === '/reports') {
            const newEndDate = endDate ? new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000) : new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
            setEndDate(newEndDate);
        } else {
            const newDate = new Date(startDate.getTime() - 24 * 60 * 60 * 1000)
            setStartDate(newDate);
        }
    };

    const handleInputClick = () => {
        datePickerRef.current.setOpen(true);
    };

    // const handleTodayClick = () => {
    //     if (pathname === '/reports') {
    //         setStartDate(currentDate);
    //         setEndDate(currentDate);
    //     } else {
    //         setStartDate(currentDate);
    //         setEndDate(currentDate);
    //     }
    // };

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    // const onChange = (dates) => {
    //     const [start, end] = dates;
    //     setStartDate(start);
    //     setEndDate(end);
    // };

    const getChangeRangeDate = (range) => {
        setStartDate(range?.startDate);
        setEndDate(range?.endDate);
    }
    return (
        <div className="input-group">
            <label htmlFor="startDate" className="form-label">
                {/* No buttons here */}
            </label>
            {pathname !== '/reports' && <>
                <button
                    onClick={handleBackwardClick}
                    className='border border-secondary'
                >
                    <HiArrowLongLeft />
                </button>
                <button
                    onClick={handleForwardClick}
                    className="mx-2 border border-secondary"
                    disabled={pathname === '/reports' ? false : startDate.getDate() === currentDate.getDate()}
                // style={{ cursor: startDate.getDate() === currentDate.getDate() ? 'not-allowed' : 'pointer' }}
                >
                    < HiArrowLongRight />
                </button>
            </>}
            <div className='date_range'>

                {pathname !== '/reports' ? (
                    <DatePicker
                        id="startDate"
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                            getDateDetails(date);
                        }}
                        dateFormat="EEE, MMM dd, yyyy"
                        placeholderText="Select a date"
                        className="form-control border-end-0"
                        minDate={threeMonthsAgo}
                        maxDate={currentDate}
                        ref={datePickerRef}
                    />
                ) : (
                    <DateRangePickerComponent getChangeRangeDate={getChangeRangeDate} />
                    // <DatePicker
                    //     selected={startDate}
                    //     onChange={onChange}
                    //     startDate={startDate}
                    //     endDate={endDate}
                    //     selectsRange
                    //     dateFormat="EEE, MMM dd, yyyy"
                    //     placeholderText="Select a date"
                    //     className="date_picker_styling form-control border-end-0"
                    //     ref={datePickerRef}
                    // />
                )}
                {pathname === '/timesheets' && (
                    <span className="input-group-text border-start-0 text-primary position-relative calender" role='button' onClick={handleInputClick}>
                        <FaRegCalendarAlt />
                    </span>
                )}
            </div>
            {/* {pathname === '/reports' && (
                <button onClick={handleTodayClick} className="btn btn-primary">Today</button>
            )} */}
        </div>
    );
};

export default DatePickerCalender;
