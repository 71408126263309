import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import SpinnerLoading from '../helper/SpinnerLoading';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import moment from 'moment';
import 'lazysizes'; 

const ImageModal = ({ imageInfo, onHide, isModalShow, imageId }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrevious = useCallback(() => {
        let index = currentImageIndex;
        do {
            index = (index === 0 ? imageInfo.length - 1 : index - 1);
        } while (!imageInfo[index]?.screenshotImageUrl && index !== currentImageIndex);
        setCurrentImageIndex(index);
        setImageLoaded(false);
    }, [currentImageIndex, imageInfo]);

    const handleNext = useCallback(() => {
        let index = currentImageIndex;
        do {
            index = (index === imageInfo.length - 1 ? 0 : index + 1);
        } while (!imageInfo[index]?.screenshotImageUrl && index !== currentImageIndex);
        setCurrentImageIndex(index);
        setImageLoaded(false);
    }, [currentImageIndex, imageInfo]);

    useEffect(() => {
        const currentIndex = imageInfo.findIndex(image => image.id === imageId);
        if (currentIndex !== -1) {
            setCurrentImageIndex(currentIndex);
        }
    }, [imageId, imageInfo]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (imageInfo.length > 1 && event.key === 'ArrowLeft') {
                handlePrevious();
            } else if (imageInfo.length > 1 && event.key === 'ArrowRight') {
                handleNext();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line
    }, [handlePrevious, handleNext]);

    const isCloseModal = () => {
        onHide(false);
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <Modal
            className="category-modal stricy"
            onHide={() => isCloseModal()}
            show={isModalShow}
            fullscreen={isModalShow}
        >
            <Modal.Header closeButton style={{ borderBottom: 'none', background: 'black' }} />
            <Modal.Body className='category-modal1 d-flex justify-content-center align-items-center'>
                <div className={`modal-navigation left ${isModalShow && imageInfo.length > 1 ? 'show' : ''}`}>
                    <FaChevronLeft className="modal-navigation-icon" onClick={handlePrevious} />
                </div>
                {imageInfo.map((image, index) => {
                    const roundedOverallActivity = Math.floor(image?.overallActivity);
                    if (index === currentImageIndex) {
                        return (
                            <div key={image.id} className='image-div-contaoner'>
                                <img
                                    data-src={image.screenshotImageUrl}
                                    alt={`alt ${image.id}`}
                                    className="modal-image lazyload"
                                    onLoad={handleImageLoad}
                                />
                                <p className='text-center mt-2 text-light'>
                                    {`${moment(image?.createdAt).format('ddd, MMM DD, YYYY')}, ${image?.screenshotTimeFrom} (${roundedOverallActivity}%)`}
                                </p>
                            </div>
                        );
                    }
                    return null;
                })}
                {!imageLoaded && <SpinnerLoading />}
                <div className={`modal-navigation right ${isModalShow && imageInfo.length > 1 ? 'show' : ''}`}>
                    <FaChevronRight className="modal-navigation-icon" onClick={handleNext} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ImageModal;
