import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Storage from "../services/localStorage.service";

const ProtectedRoute = ({
    children,
    redirectPage
}) => {
    if (!Storage.getUserData()) {
        return <Navigate to={redirectPage} replace />;
    }
    return children ? children : <Outlet />;
};

export default ProtectedRoute;