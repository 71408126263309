import { useEffect } from 'react';
import { LEEMO_TRACK } from '../constants/Constant';

const DocumentTitleHelper = (dynamicTitle) => {
    useEffect(() => {
        document.title = `${LEEMO_TRACK} | ${dynamicTitle}`;
    }, [dynamicTitle]);
};

export default DocumentTitleHelper;
