const Storage = {
    setToken: (data) => localStorage.setItem("user-token", data),
    getToken: () => localStorage.getItem("user-token"),
    setUserData: (data) => localStorage.setItem("UserData", data),
    // removeData: (key) => localStorage.removeItem(key),
    clearAllData: () => localStorage.clear(),
    getUserData: () => localStorage.getItem("UserData"),

}

export default Storage