import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const ActivityTimeWork = ({ isActivity }) => {
    const [isActive, setIsActive] = useState(0);
    const [isAvgTime, setIsAvgTime] = useState('0:00');

    useEffect(() => {
        calculateOverallActivityAndWorkTime();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActivity]);

    const calculateOverallActivityAndWorkTime = () => {
        if (!Array.isArray(isActivity) || isActivity.length === 0) {
            setIsActive(0);
            setIsAvgTime('0:00');
            return;
        }

        let totalActivity = 0;
        let validActivityCount = 0; // Count of valid activity entries
        let totalWorkTime = 0;

        isActivity.forEach((activity) => {
            if (activity && typeof activity.overallActivity === 'string') {
                const activityValue = parseFloat(activity.overallActivity);
                if (!isNaN(activityValue)) {
                    totalActivity += activityValue;
                    validActivityCount += 1;
                }
            }

            if (activity && activity.screenshotTimeFrom && activity.screenshotTimeTo) {
                const fromTimeParts = activity.screenshotTimeFrom.split(' ')[0].split(':');
                const toTimeParts = activity.screenshotTimeTo.split(' ')[0].split(':');
                if (fromTimeParts.length === 3 && toTimeParts.length === 3) {
                    // Convert fromTimeParts to seconds
                    const fromSeconds = (+fromTimeParts[0]) * 3600 + (+fromTimeParts[1]) * 60 + (+fromTimeParts[2]);
                    // Convert toTimeParts to seconds
                    const toSeconds = (+toTimeParts[0]) * 3600 + (+toTimeParts[1]) * 60 + (+toTimeParts[2]);
                    // Calculate the time difference in seconds
                    const timeDiff = toSeconds - fromSeconds;
                    // Add the time difference to totalWorkTime
                    if (!isNaN(timeDiff) && timeDiff >= 0) {
                        totalWorkTime += timeDiff;
                    }
                }
            }
        });
        setIsActive(validActivityCount > 0 ? totalActivity / validActivityCount : 0);

        if (isNaN(totalWorkTime) || totalWorkTime < 0) {
            totalWorkTime = 0;
        }

        const hours = Math.floor(totalWorkTime / 3600);
        const minutes = Math.floor((totalWorkTime % 3600) / 60);
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        setIsAvgTime(`${hours}:${formattedMinutes}`);
    };

    return (
        <Container fluid>
            <Row className='w-50 m-auto'>
                <Col className='border mx-3 rounded'>
                    <h6 className="m-2 text-secondary fs-6">WORKED TIME</h6>
                    <p className='text-center mt-3 fs-5'>{isAvgTime}</p>
                </Col>
                <Col className='border mx-3 rounded'>
                    <h6 className="m-2 text-secondary fs-6">AVG. ACTIVITY</h6>
                    <p className={`text-center mt-3 fs-5`}>{typeof isActive === 'number' ? `${Math.floor(isActive)}%` : 'N/A'}</p>
                </Col>
            </Row>
        </Container>
    );
};

export default ActivityTimeWork;
