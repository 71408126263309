import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import RoutesList from "../constants/RoutesList";
import { Link } from "react-router-dom";
import logo from '../assets/images/Logo.svg'
import { BACK_TO_LOGIN, CLICK_HEAR, COMFIRM, CURRENT, NEW, PASSWORD, RESET_PASSWORD } from "../constants/Constant";
import HidePassWord from "../assets/images/Hide.svg"
import DocumentTitleHelper from "../helper/DocumentTitleHelper";

const RestPassword = () => {
    DocumentTitleHelper(RESET_PASSWORD)

    const [passwordVisibility, setPasswordVisibility] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false
    });

    const forgotPasswordSchema = Yup.object().shape({
        oldPassword: Yup.string().required("Old password is required"),
        newPassword: Yup.string().required("New password is required"),
        confirmPassword: Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: forgotPasswordSchema,
        onSubmit: async (values) => {
            try {
                // Handle forgot password submission here
                console.log("Forgot Password form submitted with values:", values);
                // Reset form after submission
                formik.resetForm();
                toast.success("Password reset successful.");
            } catch (error) {
                toast.error("An error occurred while resetting password.");
            }
        },
    });

    const togglePasswordVisibility = (field) => {
        setPasswordVisibility({
            ...passwordVisibility,
            [field]: !passwordVisibility[field]
        });
    };

    return (
        <div>
            {/* Header */}
            <header className="header">
                <div className="container">
                    <div className="logo">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </header>

            {/* Form Container */}
            <section className="loginForm">
                <div className="container">
                    <form className="mx-auto rounded-1" onSubmit={formik.handleSubmit}>
                        <h5 className="heading5">{`${RESET_PASSWORD} in to Leemo Track`}</h5>
                        <Form.Group controlId="oldPassword">
                            <Form.Label>{`${CURRENT} ${PASSWORD}`}</Form.Label>
                            <div className="input-group">
                                <Form.Control
                                    type={passwordVisibility.oldPassword ? "text" : "password"}
                                    placeholder="Enter old password"
                                    name="oldPassword"
                                    value={formik.values.oldPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <button type="button" className="field-icon" onClick={() => togglePasswordVisibility('oldPassword')}>
                                    <img src={HidePassWord} alt="icon" />
                                </button>
                            </div>
                            {formik.touched.oldPassword && formik.errors.oldPassword && (
                                <div className="error">{formik.errors.oldPassword}</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="newPassword">
                            <Form.Label>{`${NEW} ${PASSWORD}`}</Form.Label>
                            <div className="input-group">
                                <Form.Control
                                    type={passwordVisibility.newPassword ? "text" : "password"}
                                    placeholder="Enter new password"
                                    name="newPassword"
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <button type="button" className="field-icon" onClick={() => togglePasswordVisibility('newPassword')}>
                                    <img src={HidePassWord} alt="icon" />
                                </button>
                            </div>
                            {formik.touched.newPassword && formik.errors.newPassword && (
                                <div className="error">{formik.errors.newPassword}</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="confirmPassword">
                            <Form.Label>{`${COMFIRM} ${PASSWORD}`}</Form.Label>
                            <div className="input-group">
                                <Form.Control
                                    type={passwordVisibility.confirmPassword ? "text" : "password"}
                                    placeholder="Confirm new password"
                                    name="confirmPassword"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <button type="button" className="field-icon" onClick={() => togglePasswordVisibility('confirmPassword')}>
                                    <img src={HidePassWord} alt="icon" />
                                </button>
                            </div>
                            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                <div className="error">{formik.errors.confirmPassword}</div>
                            )}
                        </Form.Group>
                        <div className="formBtn">
                            <Button className="btn btn-primary" type="submit">{RESET_PASSWORD}</Button>
                        </div>

                        <div className="form-bottom-wrap">
                            <p className="text-center">{BACK_TO_LOGIN}<Link to={RoutesList.SIGNIN} className="info"> {CLICK_HEAR} </Link> </p>
                            <hr />
                        </div>
                    </form>
                </div>
            </section>

        </div>
    );
};

export default RestPassword;
