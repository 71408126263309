import React, { useCallback, useState } from 'react'
import { Dropdown } from "react-bootstrap";
import { BiDotsVertical } from "react-icons/bi";
import { toast } from 'react-toastify';
import { useLogoutAndNavigate } from '../services/user.service';
import RestPasswordModal from '../Pages/RestPasswordModal';



const DropdownHelper = () => {
  const [restPassword, setRestPassword] = useState(false);
  const logOut = useLogoutAndNavigate();

  const handleLogout = () => {
    logOut();
    toast.success("You have been successfully logged out!")
  };

  const closeModal = useCallback(() => {
    setRestPassword(false);
  }, []);

  return (
    <>
      {restPassword && <RestPasswordModal onHide={closeModal} isModalShow={restPassword} />}
      <Dropdown className='logout_button text-end'>
        <Dropdown.Toggle className="dropdown-button">
          <span>
            <BiDotsVertical />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setRestPassword(true)}>Reset Password</Dropdown.Item>
          <Dropdown.Item onClick={() => handleLogout()}> Sign out </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default DropdownHelper