import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { COMFIRM, CURRENT, NEW, PASSWORD, RESET_PASSWORD } from "../constants/Constant";
import HidePassWord from "../assets/images/Hide.svg"
import DocumentTitleHelper from "../helper/DocumentTitleHelper";
import instance from "../services/api.service";
import ApiRouteList from "../constants/ApiRouteList";
import { FaRegEye } from "react-icons/fa";

const RestPasswordModal = ({ onHide, isModalShow }) => {
    DocumentTitleHelper(RESET_PASSWORD)

    const [passwordVisibility, setPasswordVisibility] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false
    });

    const forgotPasswordSchema = Yup.object().shape({
        oldPassword: Yup.string().required("Old password is required"),
        newPassword: Yup.string().required("New password is required"),
        confirmPassword: Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: forgotPasswordSchema,
        onSubmit: async (values) => {
            try {
                const { data, status } = await instance.put(ApiRouteList.USER_REST_PASSWORD, {
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword
                });
                if (status === 200) {
                    // formik.resetForm();
                    toast.success(data?.message);
                    onHide();
                }
            } catch (error) {
                // console.error("Error updating password:", error);
                toast.error("An error occurred while resetting password.");
            }
        },
    });

    const togglePasswordVisibility = (field) => {
        setPasswordVisibility({
            ...passwordVisibility,
            [field]: !passwordVisibility[field]
        });
    };

    const isCloseModal = () => {
        onHide(false);
    }

    return (
        <Modal
            onHide={() => isCloseModal()}
            show={isModalShow}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton >
                <h5 className="heading5">{`${RESET_PASSWORD} in to Leemo Track`}</h5>
            </Modal.Header>
            <Modal.Body className="mx-5">
                <div className="container">
                    <form className="mx-auto rounded-1" onSubmit={formik.handleSubmit}>
                        <Form.Group controlId="oldPassword">
                            <Form.Label>{`${CURRENT} ${PASSWORD}`}</Form.Label>
                            <div className="input-group">
                                <Form.Control
                                    type={passwordVisibility.oldPassword ? "text" : "password"}
                                    placeholder="Enter old password"
                                    name="oldPassword"
                                    value={formik.values.oldPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <button type="button" className="field-icon" onClick={() => togglePasswordVisibility('oldPassword')}>
                                    {passwordVisibility.oldPassword ? <FaRegEye size={20} color="gray" /> : <img src={HidePassWord} alt="icon" />}
                                </button>
                            </div>
                            {formik.touched.oldPassword && formik.errors.oldPassword && (
                                <div className="error">{formik.errors.oldPassword}</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="newPassword">
                            <Form.Label>{`${NEW} ${PASSWORD}`}</Form.Label>
                            <div className="input-group">
                                <Form.Control
                                    type={passwordVisibility.newPassword ? "text" : "password"}
                                    placeholder="Enter new password"
                                    name="newPassword"
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <button type="button" className="field-icon" onClick={() => togglePasswordVisibility('newPassword')}>
                                    {passwordVisibility.newPassword ? <FaRegEye size={20} color="gray" /> : <img src={HidePassWord} alt="icon" />}
                                </button>
                            </div>
                            {formik.touched.newPassword && formik.errors.newPassword && (
                                <div className="error">{formik.errors.newPassword}</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="confirmPassword">
                            <Form.Label>{`${COMFIRM} ${PASSWORD}`}</Form.Label>
                            <div className="input-group">
                                <Form.Control
                                    type={passwordVisibility.confirmPassword ? "text" : "password"}
                                    placeholder="Confirm new password"
                                    name="confirmPassword"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <button type="button" className="field-icon" onClick={() => togglePasswordVisibility('confirmPassword')}>
                                    {passwordVisibility.confirmPassword ? <FaRegEye size={20} color="gray" /> : <img src={HidePassWord} alt="icon" />}
                                </button>
                            </div>
                            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                <div className="error">{formik.errors.confirmPassword}</div>
                            )}
                        </Form.Group>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-primary mt-4" onClick={() => formik.handleSubmit()}>{RESET_PASSWORD}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RestPasswordModal;
