import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import instance from '../services/api.service';
import ApiRouteList from '../constants/ApiRouteList';
import { toast } from 'react-toastify';
import { SERVER_ERROR } from '../constants/Constant';
import { useLogoutAndNavigate } from '../services/user.service';
import SessionStorage from '../services/sessionStorage.service';

const UserSelecter = ({ onUserSelect }) => {
    const [detailSheet, setDetailSheet] = useState([]);
    const [selectedUser, setSelectedUser] = useState(SessionStorage.getUserId() || '');
    const mailMounted = useRef(false);
    const logOut = useLogoutAndNavigate();
    // const UserIdSettion = SessionStorage.getUserId()

    useEffect(() => {
        if (!mailMounted.current) {
            getUserDetails();
            mailMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Set the first user as selected by default
        if (detailSheet.length > 0 && !selectedUser) {
            setSelectedUser(detailSheet[0]?.id);
            onUserSelect(detailSheet[0]?.id);

        }

        // eslint-disable-next-line
    }, [detailSheet]);

    const getUserDetails = async () => {
        try {
            const { data } = await instance.get(ApiRouteList.USER);
            setDetailSheet(data?.users);
        } catch (error) {
            if (error.response?.data) {
                logOut();
                toast.error(error.response.data.reason);
            } else {
                toast.error(error.message || SERVER_ERROR);
            }
        }
    };


    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedUser(selectedValue);
        onUserSelect(selectedValue);
    };

    return (
        <Form.Select aria-label="Default select example" onChange={handleChange} value={selectedUser} className='form_select_user'>
            {detailSheet?.map((user, index) => (
                <option value={user?.id} key={index}>{`${user?.firstName} ${user?.lastName}`}</option>
            ))}
        </Form.Select>
    );
};

export default UserSelecter;
