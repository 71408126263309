import React, { useState, useEffect, useRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import moment from 'moment';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { FaRegCalendarAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const DateRangePickerComponent = ({ getChangeRangeDate }) => {
  const [range, setRange] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });

  const pickerRef = useRef(null);

  const handleApply = (event, picker) => {
    const newRange = {
      startDate: picker.startDate.format('YYYY-MM-DD'),
      endDate: picker.endDate.format('YYYY-MM-DD'),
    };
    setRange(newRange);
    if (getChangeRangeDate) {
      getChangeRangeDate(newRange);
    }
  };

  const handleInputChange = (event) => {
    // This is a dummy handler to suppress the warning.
    // The actual value is controlled by the date picker.
  };

  useEffect(() => {
    if (getChangeRangeDate) {
      getChangeRangeDate(range);
    }
  }, [range, getChangeRangeDate]);

  const formattedRange = `${moment(range.startDate).format('ddd, MMM DD, YYYY')} - ${moment(range.endDate).format('ddd, MMM DD, YYYY')}`;

  const handleTodayClick = () => {
    const today = moment();
    setRange({
      startDate: today.format('YYYY-MM-DD'),
      endDate: today.format('YYYY-MM-DD'),
    });
    if (pickerRef.current) {
      pickerRef.current.setStartDate(today);
      pickerRef.current.setEndDate(today);
    }
  };

  return (
    <>
      <Form>
        <Form.Group>
          <DateRangePicker
            onApply={handleApply}
            ref={pickerRef}
            initialSettings={{
              startDate: moment(range.startDate),
              endDate: moment(range.endDate),
              ranges: {
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
              },
              alwaysShowCalendars: true,
            }}
          >
            <InputGroup>
              <Form.Control
                type="text"
                value={formattedRange}
                placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                onChange={handleInputChange}
                style={{ width: '321px' }} 
              />
              <InputGroup.Text className="bg-transparent border-0">
                <FaRegCalendarAlt className="text-muted" />
              </InputGroup.Text>
            </InputGroup>
          </DateRangePicker>
        </Form.Group>
      </Form>
      <button onClick={handleTodayClick} className="btn btn-primary">Today</button>
    </>
  );
};

export default DateRangePickerComponent;
