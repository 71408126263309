import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Storage from "../services/localStorage.service";
import RoutesList from "../constants/RoutesList";

const PublicRoute = ({
    children
}) => {
    if (Storage.getUserData()) {
        return <Navigate to={RoutesList.SIGNIN} replace />;
    }
    return children ? children : <Outlet />;
};

export default PublicRoute;