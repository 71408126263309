import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import DocumentTitleHelper from "../../helper/DocumentTitleHelper";
import SpinnerLoading from "../../helper/SpinnerLoading";
import { USER_TABLE } from "../../constants/UserTable";
import { useDispatch } from "react-redux";
import { onSetUserList } from "../../redux/Slices/isUserList";
import ProjectFilterDrawer from "../../components/ProjectFilterDrawer";
import GroupFilter from "../../components/GroupFilter";
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { NO_DATA_FOUND, REPORTS, SERVER_ERROR } from "../../constants/Constant";
import DatePickerCalender from "../../components/DatePickerCalender";
import moment from "moment";
import instance from "../../services/api.service";
import ApiRouteList from "../../constants/ApiRouteList";
import { useLogoutAndNavigate } from "../../services/user.service";

const TimeActivitys = () => {
    const [detailSheet, setDetailSheet] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isShowDrawer, setIsShowDrawer] = useState(false);
    const [groupBy, setGroupBy] = useState('date');
    const [columns, setColumns] = useState([]);
    const [collapsedTables, setCollapsedTables] = useState([]);
    const [isFromDate, setIsFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [isToDate, setIsToDate] = useState(moment().format('YYYY-MM-DD'));

    const mailMounted = useRef(false);
    const dispatch = useDispatch();

    DocumentTitleHelper(REPORTS);
    const logOut = useLogoutAndNavigate();


    useEffect(() => {
        if (!mailMounted.current) {
            fetchData();
            mailMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData();

        // eslint-disable-next-line
    }, [groupBy, isFromDate, isToDate]);

    useEffect(() => {
        const tableColumns = USER_TABLE?.map(({ col_Key, columnName, bgColor, color, isVisible }) => ({
            accessorKey: col_Key,
            header: columnName,
            bgColor,
            color,
            col_Key,
            isVisible: groupNameToAccessorKey[groupBy] === col_Key ? false : isVisible
        })).filter(x => x.isVisible === true);

        setColumns(tableColumns);
        // eslint-disable-next-line
    }, [groupBy]);

    const fetchData = async () => {
        if (!isFromDate || !isToDate || !groupBy) return;

        setIsLoading(true);
        try {
            const { data } = await instance.get(`${ApiRouteList.OVERALL_ACTIVITY}?fromDate=${isFromDate}&toDate=${isToDate}&groupBy=${groupBy}`);
            updateDetailSheet(data);
        } catch (error) {
            if (error.response?.data) {
                logOut();
                toast.error(error.response.data.reason);
            } else {
                toast.error(error.message || SERVER_ERROR);
            }
        } finally {
            setTimeout(() => setIsLoading(false), 1000);
        }
    };

    const handleSaveFilters = async (isClient, isProject, isTask) => {
        if (isClient.length || isProject.length || isTask.length) {
            setIsLoading(true);
            try {
                const { data } = await instance.post(`${ApiRouteList.Filter_OVERALL_ACTIVITY}?fromDate=${isFromDate}&toDate=${isToDate}&groupBy=${groupBy}`, {
                    userIds: isClient,
                    projectIds: isProject,
                    taskIds: isTask
                });
                updateDetailSheet(data);
            } catch (error) {
                toast.error(error.message || SERVER_ERROR);
            } finally {
                setTimeout(() => setIsLoading(false), 1000);
            }
        }
    };

    const updateDetailSheet = (data) => {
        setDetailSheet(data);
        setCollapsedTables(new Array(data?.projects?.length).fill(false));
        if (data?.length) {
            dispatch(onSetUserList({ userList: data }));
        }
    };

    const handleOpenDrawer = () => setIsShowDrawer(true);
    const handleCloseDrawer = () => setIsShowDrawer(false);

    const getDatabyGroup = (value) => setGroupBy(value);

    const convertSecondsToHHMMSS = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const formatNumber = (num) => (num < 10 ? `0${num}` : `${num}`);

        return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
    };

    const groupNameToAccessorKey = {
        date: 'date',
        project: 'projectName',
        task: 'taskName',
        user: 'member'
    };

    const getRowText = (option, col_Key) => {
        const transformations = {
            date: moment(option['createdAt']).format('ddd, MMM DD, YYYY'),
            projectName: option[col_Key],
            taskName: option[col_Key],
            member: `${option?.userFirstName} ${option?.userLastName}`,
            totalSeconds: convertSecondsToHHMMSS(option[col_Key]),
            totalOverallActivity: `${Math.floor(option[col_Key])}%`,
        };

        return transformations[col_Key] || null;
    };

    const getDateDetails = (date) => {
        setIsFromDate(moment(new Date(date?.startDate)).format('YYYY-MM-DD'));
        setIsToDate(date?.endDate ? moment(new Date(date?.endDate)).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
    };

    const toggleTable = (index) => {
        setCollapsedTables(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    return (
        <Container fluid className="mt-3 scrollable-tbody">
            {isLoading && <SpinnerLoading />}
            <ProjectFilterDrawer isShowDrawer={isShowDrawer} handleCloseDrawer={handleCloseDrawer} handleSaveFilters={handleSaveFilters} fetchData={fetchData} />
            <div>
                <Row className="mb-3">
                    <Col lg={2}>
                        <GroupFilter getDatabyGroup={getDatabyGroup} />
                    </Col>
                    <Col lg={5}>
                        <DatePickerCalender getDateDetails={getDateDetails} />
                    </Col>
                    <Col xl={3} xxl={4}>
                        {/* <UserSelecter onUserSelect={handleUserSelect} /> */}
                    </Col>
                    <Col lg={1} className="">
                        <Button onClick={handleOpenDrawer}>Filters</Button>
                    </Col>
                </Row>
                <table className="categoryList-tablewrap tableResizer_max">
                    <thead>
                        <tr>
                            {columns.map((col, key) => (
                                <th key={key} style={{ background: col.bgColor }}>
                                    {col.header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {detailSheet?.projects?.map((row, index) => {
                            const isTableCollapsed = collapsedTables[index];
                            const isLastRow = index === detailSheet.projects.length - 1;

                            return (
                                <Fragment key={index}>
                                    <tr className="specific-row-class">
                                        <td colSpan={columns.length} className="table_row_style">
                                            {row['date'] ? moment(row[groupBy]).format('ddd, MMM DD, YYYY') : row['projectId'] ? row?.records[0]?.projectName : row['taskId'] ? row?.records[0]?.taskName : row['userId'] ? `${row?.records[0]?.userFirstName} ${row?.records[0]?.userLastName}` : ''}
                                        </td>
                                    </tr>
                                    <tr onClick={() => toggleTable(index)} className="border">
                                        {columns.map((col, colIdx) => (
                                            <td key={colIdx}>
                                                {col.accessorKey === 'date' ? (
                                                    <>
                                                        {!isTableCollapsed ? <BsChevronDown className='mx-2' /> : <BsChevronRight className='mx-2' />}
                                                        <span className="table_font-style">TOTAL</span>
                                                    </>
                                                ) : col.accessorKey === 'totalSeconds' ? convertSecondsToHHMMSS(row?.totalDayTime) : col.accessorKey === 'totalOverallActivity' ? `${Math.floor(row?.totalOverallActivityOfDay)}%` : col.accessorKey !== 'date' && col.accessorKey === 'projectName' && groupBy === 'date' ? (
                                                    <>
                                                        {!isTableCollapsed ? <BsChevronDown className='mx-2' /> : <BsChevronRight className='mx-2' />}
                                                        <span className="table_font-style">TOTAL</span>
                                                    </>
                                                ) : ''}
                                            </td>
                                        ))}
                                    </tr>
                                    {!isTableCollapsed && row.records.map((option, idx) => (
                                        <tr key={idx} className="border">
                                            {columns.map((col, colIdx) => (
                                                <td key={colIdx} colSpan={1} className="table_font-style" title={(col.accessorKey === 'projectName' || col.accessorKey === 'taskName') ? option[col.accessorKey] : null}>
                                                    {getRowText(option, col.accessorKey)}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    {!isLastRow && (
                                        <tr style={{ height: '50px', backgroundColor: 'red' }}></tr>
                                    )}
                                </Fragment>
                            );
                        })}
                        {!detailSheet?.projects?.length && (
                            <tr>
                                <td colSpan={columns.length} className="text-center">
                                    {NO_DATA_FOUND}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Container>
    );
};

export default TimeActivitys;
