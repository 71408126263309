import React from 'react'
import { REPORTS, SCREENSHOTS } from '../constants/Constant';
import DropdownHelper from '../helper/DropdownHelper';
// import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import { PiExportThin } from "react-icons/pi";
// import { ExportToExcelHelper } from "../helper/ExportToExcelHelper";
// import { NO_DATA_FOUND } from "../constants/Constant"
import { Col, Row } from 'react-bootstrap';
import RoutesList from '../constants/RoutesList';

const MainContentHeader = ({ pathname }) => {
    // const isUserList = useSelector((item) => item.isUserList);
    // const isUserDetails = isUserList?.userList

    const tabName = pathname === RoutesList.TIMESHEETS ? SCREENSHOTS : REPORTS;

    // Export function
    // const handleExport = () => {
    //     if (isUserDetails.length === 0) {
    //         toast.error(NO_DATA_FOUND);
    //         return;
    //     }

    //     const dataToExport = isUserDetails.map(user => ({
    //         Project: user.project,
    //         Member: user.member,
    //         To_do: user.todo,
    //         Time: user.time,
    //         Activity: user.activity,
    //         Total_Spent: user.total_spent

    //     }));

    //     ExportToExcelHelper(dataToExport, "User List");
    // };
    return (
        <Row className="bg-white main_dashboardHeasder rounded-2">
            <Col md={10}>
                <h4 className="text-secondary">{tabName}</h4>
            </Col>
            <Col md={2}>
                {/* {pathname === RoutesList.REPORTS && <span onClick={handleExport} role="button">
                    <PiExportThin title="export sheet" />Export
                </span>} */}
                <DropdownHelper />
            </Col>
        </Row>
    )
}

export default MainContentHeader