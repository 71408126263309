import moment from "moment";
import React from "react";
import { ProgressBar as BootstrapProgressBar, OverlayTrigger, Tooltip } from "react-bootstrap";

const ProgressBar = ({ image }) => {
  // Destructure image object with default values
  const { overallActivity = 0, mouseActivity = 0, keyboardActivity = 0, timeSlabFrom, timeSlabTo, screenshotTimeFrom, screenshotTimeTo } = image || {};

  // Parse the time strings using moment.js
  const startTime = moment(screenshotTimeFrom, 'hh:mm:ss a');
  const endTime = moment(screenshotTimeTo, 'hh:mm:ss a');

  // Calculate the difference in minutes
  const differenceInMinutes = endTime.isValid() && startTime.isValid() ? endTime.diff(startTime) / 60000 : 0;

  // Calculate rounded values
  const roundedOverallActivity = overallActivity > 1 ? Math.floor(overallActivity) : Math.ceil(overallActivity);
  const roundedmouseActivity = Math.floor(mouseActivity);
  const roundedkeyboardActivity = Math.floor(keyboardActivity);

  // Determine progress bar color
  const timeActivityColor = roundedOverallActivity > 50 ? 'success' : roundedOverallActivity > 25 ? 'warning' : 'danger';

  // Construct time range and percentage text
  const timeRange = `${timeSlabFrom} - ${timeSlabTo}`;
  const percentageText = `${roundedOverallActivity}% of ${Math.round(differenceInMinutes)} minutes`;

  // Tooltip content
  const tooltip = (
    <Tooltip id="tooltip" className="custom-tooltip">
      <div>
        Overall: {roundedOverallActivity}%
      </div>
      <div className="mt-1">
        Mouse: {roundedmouseActivity}% Keyboard: {roundedkeyboardActivity}%
      </div>
    </Tooltip>
  );

  return (
    <>
      <p className="BootstrapProgressBar-time">{timeRange}</p>
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <BootstrapProgressBar now={roundedOverallActivity} label={`${roundedOverallActivity}%`} visuallyHidden variant={timeActivityColor} className="BootstrapProgressBar-size" />
      </OverlayTrigger>
      <p className="BootstrapProgressBar-percentage">{percentageText}</p>
    </>
  );
};

export default ProgressBar;
