const ApiRouteList = {
    USER_LOGIN: "/user/login",
    USER: "/users",
    USER_REST_PASSWORD: "/user/reset-password",
    TASK_RECORDS: (id) => `task-records/${id}`,
    OVERALL_ACTIVITY: "/overall-activity",
    FILTER_USERS: "/users",
    FILTER_PROJECT: "/projects",
    FILTER_TASK: "/tasks",
    PROJECT_TASK_MEMBER: "/project-task-member",
    Filter_OVERALL_ACTIVITY: "/filter-overall-activity"
}
export default ApiRouteList;
