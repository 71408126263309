import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import RoutesList from './constants/RoutesList';
import SignIn from './Pages/SignIn';
import Layout from './layout/Layout';
import ProtectedRoute from './protected/ProtectedRoute';
import PublicRoute from './protected/PublicRoute';
import RestPassword from './Pages/RestPassword';

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Attach the event listener for local storage changes
    window.addEventListener('storage', handleStorageChange);

    // Periodically check session storage
    const intervalId = setInterval(checkSessionStorage, 800);

    return () => {
      // Cleanup the event listener and interval when the component unmounts
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [navigate]);

  const handleStorageChange = (e) => {
    // Check if the local storage is cleared
    if (e.key === null) {
      // Navigate to the login page
      navigate(RoutesList.SIGNIN);
    }
  };

  const checkSessionStorage = () => {
    if (!sessionStorage.length) {
      // Session storage is cleared, clear local storage and navigate to the login page
      localStorage.clear();
      navigate(RoutesList.SIGNIN);
    }
  };

  return (
    <Routes>
      <Route element={<PublicRoute redirectPage={RoutesList.SIGNIN} />}>
        <Route path={RoutesList.SIGNIN} element={<SignIn />} />
        <Route path={RoutesList.REST_PASSWORD} element={<RestPassword />} />
      </Route>
      <Route element={<ProtectedRoute redirectPage={RoutesList.SIGNIN} />}>
        <Route path={`${RoutesList.LAYOUT}/*`} element={<Layout />} />
      </Route>
    </Routes>
  );
};

export default App;
