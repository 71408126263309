export const USER_TABLE = [
    {
        col_Key: 'date',
        columnName: 'Date',
        bgColor: '#ddd',
        "isVisible": true,
    },
    {
        col_Key: 'projectName',
        columnName: 'Project',
        bgColor: '#ddd',
        "isVisible": true,
    },
    {
        col_Key: 'member',
        columnName: 'Member',
        bgColor: '#ddd',
        "isVisible": true,
    },
    {
        col_Key: 'taskName',
        columnName: 'Task',
        bgColor: '#ddd',
        "isVisible": true,
    },
    {
        col_Key: 'totalSeconds',
        columnName: 'Time',
        bgColor: '#ddd',
        "isVisible": true,
    },
    {
        col_Key: 'totalOverallActivity',
        columnName: 'Activity',
        bgColor: '#ddd',
        "isVisible": true,
    },
    // {
    //     col_Key: 'memo',
    //     columnName: 'Memo',
    //     bgColor: '#ddd',
    //     "isVisible": true,
    // }
];